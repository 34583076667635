import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { pushCleverEvent } from '../api/analytics/clevertap'
import { homePage } from '../data/pages'
import useScreenName from '../hooks/clevertap/useScreenName'

const pageName = '404'

const statusCode = 404

const Page404 = () => {
  const router = useRouter()
  const ScreenProvider = useScreenName(pageName)

  useEffect(() => {
    pushCleverEvent('weberror', { statusCode })
    router.replace(homePage)
  })

  return <ScreenProvider></ScreenProvider>
}

export default Page404
